import * as React from 'react';
import { Link } from 'gatsby';
import { useStoreon } from 'hooks';
import cn from 'classnames';
import { AuthRequiredLink } from './AuthRequiredLink';
import * as s from './CategorySidebar.module.css';

export type Props = {
  readonly activeId?: number;
  readonly showMobileCategorySidebar?: boolean;
};

let categoryListScrollPos = {
  top: 0,
  left: 0,
};

const keepActiveCategoryVisible = (listRef: React.RefObject<HTMLUListElement>) => {
  const list = listRef.current;
  if (!list) return;
  const activeCategory = list.querySelector('.active') as HTMLLIElement | null;
  if (!activeCategory) return;
  const listScrollPos = {
    top: list.scrollTop,
    left: list.scrollLeft,
  };
  const listRect = list.getBoundingClientRect();
  const activeCategoryPos = {
    top: activeCategory.offsetTop,
    left: activeCategory.offsetLeft,
  };
  const activeCategoryRect = activeCategory.getBoundingClientRect();

  /* scroll to active category: */

  // if category above visible area
  if (activeCategoryPos.top - 50 < listScrollPos.top) {
    list.scrollTo({ top: activeCategoryPos.top - 50, behavior: 'smooth' });
  }

  // if category below visible area
  if (activeCategoryPos.top + activeCategoryRect.height + 50 > listScrollPos.top + listRect.height) {
    list.scrollTo({
      top: activeCategoryPos.top + activeCategoryRect.height - listRect.height + 50,
      behavior: 'smooth',
    });
  }

  // if category on the left (for mobile)
  if (activeCategoryPos.left - 50 < listScrollPos.left) {
    list.scrollTo({ left: activeCategoryPos.left - 50, behavior: 'smooth' });
  }

  // if category on the right (for mobile)
  if (activeCategoryPos.left + activeCategoryRect.width + 50 > listScrollPos.left + listRect.width) {
    list.scrollTo({
      left: activeCategoryPos.left + activeCategoryRect.width - listRect.width + 50,
      behavior: 'smooth',
    });
  }
};

type ItemProps = {
  activeId?: number;
  icon?: React.ReactNode;
  data: { id: number; name: string; pageUrl: string };
  authRequired?: boolean;
  classNames?: string;
};

export const SidebarItem = ({ data, activeId, icon, authRequired, classNames }: ItemProps) => {
  const isActive = data.id === activeId;
  let className = 'mr-1.5 group border-l-4 border-transparent lg:mr-0 lg:whitespace-nowrap lg:border-0';
  let cnText = 'font-medium text-sm text-black duration-150 group-hover:text-orange-50 lg:text-gray-50';
  if (isActive) {
    className += 'border-l-4 border-red-50 lg:border-0 bg-orange-400 lg:bg-white active';
    cnText += 'text-black';
  }

  if (classNames) {
    cnText = cn(classNames, cnText);
  }

  if (authRequired) {
    return (
      <li className={className}>
        <AuthRequiredLink
          to={data.pageUrl}
          classNames="flex items-center py-3  h-full w-full pr-3 ml-1.5 lg:ml-0 lg:pl-3 lg:h-full"
        >
          {icon}
          <span className={cnText}>{data.name}</span>
        </AuthRequiredLink>
      </li>
    );
  }

  return (
    <li className={className}>
      <Link to={data.pageUrl} className="flex items-center py-3 pr-3 h-full w-full ml-1.5 lg:ml-0 lg:pl-3 lg:h-full">
        {icon}
        <span className={cnText}>{data.name}</span>
      </Link>
    </li>
  );
};

export const SidebarWrapper: React.FC<Props> = ({ activeId, showMobileCategorySidebar = false, children }) => {
  const { inApp } = useStoreon('inApp');

  const [scroll, setScroll] = React.useState(false);
  const categoryListRef = React.useRef<HTMLUListElement>(null);

  const handleScroll = React.useCallback(() => {
    const position = document.body.scrollTop || window.scrollY;
    if (position >= 190) return setScroll(true);
    return setScroll(false);
  }, []);

  const handleCategoryListScroll = (e: Event) => {
    const list = e.target as HTMLUListElement | null;
    if (!list)
      categoryListScrollPos = {
        top: 0,
        left: 0,
      };
    else
      categoryListScrollPos = {
        top: list.scrollTop,
        left: list.scrollLeft,
      };
  };

  React.useEffect(() => {
    if (!inApp) {
      window.addEventListener('scroll', handleScroll);
    }

    if (categoryListRef.current) {
      categoryListRef.current.scrollTo(categoryListScrollPos);
      categoryListRef.current.addEventListener('scroll', handleCategoryListScroll);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
      categoryListRef.current?.removeEventListener('scroll', handleCategoryListScroll);
    };
  }, [categoryListRef, inApp, handleScroll]);

  React.useEffect(() => {
    keepActiveCategoryVisible(categoryListRef);
  }, [activeId]);

  const rootClassNames = [
    'sticky w-72 lg:w-full lg:bottom-0 lg:left-0 lg:top-auto lg:h-14 transition-all duration-75 lg:shadow-5xl',
  ];
  if (showMobileCategorySidebar) {
    rootClassNames.push('lg:fixed z-40');
  } else {
    rootClassNames.push('lg:hidden');
  }
  if (scroll) {
    rootClassNames.push('mt-[-126px]');
  }

  return (
    <aside className={rootClassNames.join(' ')} style={{ minWidth: '205px' }}>
      <ul
        ref={categoryListRef}
        className={cn(
          s.list,
          inApp ? 'h-full' : ' lg:h-10',
          !inApp && (scroll ? s.scroll__active : s.scroll),
          'overflow-auto fixed lg:flex lg:bg-white lg:sticky',
        )}
        id="styled-scroll"
      >
        {children}
      </ul>
    </aside>
  );
};
