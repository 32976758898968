import * as React from 'react';
import { gql } from '@urql/core';
import { captureException } from 'utils';
import { DeliveredNotifySwitch_User, useBasicDeliveredNotifySwitchMutation } from '../generated/graphql';
import { Switch } from './Switch';

type Props = {
  user: DeliveredNotifySwitch_User;
};

export const DeliveredNotifySwitch = ({ user }: Props) => {
  const [, mutate] = useBasicDeliveredNotifySwitchMutation();

  const handleChange = async () => {
    try {
      await mutate({ enableDeliveredNotify: !user.enableDeliveredNotify });
    } catch (err) {
      captureException(err);
      // eslint-disable-next-line no-alert
      alert(err.message);
    }
  };

  return (
    <div className="flex mb-4">
      <p className="pr-[5px]">Присылать уведомление, когда заказ доставлен</p>
      <div className="lg:order-2 flex items-center">
        <Switch value={user.enableDeliveredNotify} onChange={handleChange} />
      </div>
    </div>
  );
};

gql`
  fragment DeliveredNotifySwitch_user on User {
    id
    enableDeliveredNotify
  }

  mutation DeliveredNotifySwitchMutation($input: ChangeProfileInput!) {
    result: changeProfile(input: $input) {
      ... on ChangeProfilePayload {
        user {
          id
          enableDeliveredNotify
        }
      }

      ... on ErrorPayload {
        message
      }
    }
  }
`;
