import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { CategorySidebarQuery } from 'generated/graphql';
import { useLocation } from '@reach/router';
import FavoriteOrangeIcon from './icons/FavoriteOrange';
import CartIcon from './icons/Cart';
import { SidebarItem, SidebarWrapper } from './Sidebar';
import { Image } from './Image';

export type Props = {
  readonly activeId?: number;
  readonly showMobileCategorySidebar?: boolean;
};

export const CategorySidebar = ({ activeId, showMobileCategorySidebar = false }: Props) => {
  const location = useLocation();
  const data = useStaticQuery<CategorySidebarQuery>(graphql`
    query CategorySidebarQuery {
      menuCategories {
        id
        name
        pageUrl
        iconUrl(size: 10)
      }
    }
  `);

  return (
    <SidebarWrapper activeId={activeId} showMobileCategorySidebar={showMobileCategorySidebar}>
      <SidebarItem
        data={{
          id: 0,
          name: 'Любимые товары',
          pageUrl: '/my/favorites',
        }}
        activeId={location.pathname.indexOf('/my/favorites')}
        authRequired
        icon={
          <div className="mr-5 scale-75 transform lg:hidden">
            <FavoriteOrangeIcon size={40} />
          </div>
        }
      />
      <SidebarItem
        data={{
          id: 0,
          name: 'Купленные товары',
          pageUrl: '/my/bought',
        }}
        activeId={location.pathname.indexOf('/my/bought')}
        authRequired
        icon={
          <div className="mr-5 scale-75 transform lg:hidden">
            <CartIcon size={40} />
          </div>
        }
      />
      {data.menuCategories.map((i) => (
        <SidebarItem
          key={i.id}
          data={i}
          activeId={activeId}
          icon={<Image src={i.iconUrl} alt={i.name} className="mr-5 lg:hidden" width={40} height={40} />}
        />
      ))}
    </SidebarWrapper>
  );
};
