import * as React from 'react';
import { CategorySidebar, Props as CategorySidebarProps } from './CategorySidebar';
import { TimeDelivery } from './TimeDelivery';

export const CatalogLayoutWithoutMain: React.FC<CategorySidebarProps> = ({ children, ...sidebarProps }) => (
  <>
    <TimeDelivery />
    <div className="container flex flex-grow lg:pb-5">
      <div className="flex flex-grow w-full mt-2 px-[6px] ">
        <CategorySidebar {...sidebarProps} />
        <div style={{ width: 'calc(100% - 300px)' }} className="flex-1 flex flex-col">
          {children}
        </div>
      </div>
    </div>
  </>
);
