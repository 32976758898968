import * as React from 'react';
import { gql } from '@urql/core';
import { Controller, useForm } from 'react-hook-form';
import { validateEmail } from 'utils';
import { MySettingsForm_User, useBasicMySettingsFormMutation } from 'generated/graphql';
import { TextField } from './TextField';
import { ProgressButton } from './ProgressButton';

export const MySettingsForm = ({ user }: { user: MySettingsForm_User }) => {
  const [error, setError] = React.useState<Error | null>(null);

  const [mutationState, mutate] = useBasicMySettingsFormMutation();

  const { control, handleSubmit, formState, reset } = useForm<{ name: string; email: string }>({
    mode: 'onChange',
    defaultValues: {
      name: user.name || '',
      email: user.email || '',
    },
  });

  const { errors, isDirty, isValid } = formState;

  const onSubmit = handleSubmit(async (input) => {
    setError(null);
    try {
      const res = await mutate({ name: input.name, email: input.email });

      if (res?.__typename === 'ChangeProfilePayload') {
        reset({
          name: res.user.name || '',
          email: res.user.email || '',
        });
      }
    } catch (e) {
      setError(e);
    }
  });

  const btnDisabled = !isDirty || !isValid || mutationState.fetching;

  const errorView = error && <p className="text-orange-50 text-sm text-center">{error.message}</p>;

  return (
    <form onSubmit={onSubmit}>
      <Controller
        control={control}
        name="name"
        rules={{
          required: 'Имя должно быть от 3 до 50 символов',
          minLength: { value: 3, message: 'Имя должно быть от 3 до 50 символов' },
          maxLength: { value: 50, message: 'Имя должно быть от 3 до 50 символов' },
        }}
        render={({ field }) => (
          <TextField
            label="Введите имя"
            error={errors.name?.message}
            value={field.value}
            onChangeText={field.onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="email"
        rules={{
          validate: validateEmail,
        }}
        render={({ field }) => (
          <TextField
            label="Введите e-mail"
            error={errors.email?.type === 'validate' ? 'Введите корректный email' : null}
            value={field.value}
            onChangeText={field.onChange}
          />
        )}
      />
      {errorView}
      <ProgressButton
        disabled={btnDisabled}
        type="submit"
        isProgress={mutationState.fetching}
        styles="bg-orange-50 h-10 px-3 rounded-md mb-5 mt-2 text-white xs:ml-0 btn-orange"
      >
        Сохранить
      </ProgressButton>
    </form>
  );
};

gql`
  fragment MySettingsForm_user on User {
    name
    email
  }

  mutation MySettingsFormMutation($input: ChangeProfileInput!) {
    result: changeProfile(input: $input) {
      ... on ChangeProfilePayload {
        user {
          id
          ...MySettingsForm_user
        }
      }

      ... on ErrorPayload {
        message
      }
    }
  }
`;
