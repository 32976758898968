import * as React from 'react';
import { Link } from 'gatsby';
import { gql } from '@urql/core';
import { useStoreon } from 'hooks';
import { captureException, formatPhone } from 'utils';
import { CatalogLayout } from 'components/CatalogLayout';
import { PageError } from 'components/PageError';
import { Loader } from 'components/Loader';
import { AuthRequired } from 'components/AuthRequired';
import { MySettingsForm } from 'components/MySettingsForm';
import { UserActiveAddress } from 'components/UserActiveAddress';
import { ModalButton } from 'components/ModalLogic';
import { useMySettingsPageQuery } from 'generated/graphql';
import { DeliveredNotifySwitch } from 'components/DeliveredNotifySwitch';
import { ContentTitle } from 'components/ContentTitle';

const linkCn = 'block transition-colors duration-300 ease-in-out hover:text-orange-50 mb-5';

const Settings = () => {
  const [queryRes, refetchQuery] = useMySettingsPageQuery();
  const { dispatch } = useStoreon();

  if (queryRes.error) {
    const retry = () => refetchQuery({ requestPolicy: 'network-only' });
    return (
      <CatalogLayout>
        <PageError error={queryRes.error} retry={retry} className="justify-center" />
      </CatalogLayout>
    );
  }

  if (queryRes.fetching && !queryRes.data) {
    return (
      <CatalogLayout>
        <Loader className="flex justify-center" />
      </CatalogLayout>
    );
  }

  if (!queryRes.data) {
    captureException(new Error(`my settings page data is null`));
    return null;
  }

  if (!queryRes.data.viewer.user) {
    return (
      <CatalogLayout>
        <AuthRequired />
      </CatalogLayout>
    );
  }

  const { user } = queryRes.data.viewer;

  const logOut = () => {
    // eslint-disable-next-line
    if (confirm('Вы уверены, что хотите выйти из аккаунта?')) {
      dispatch('setToken', null);
    }
  };

  return (
    <CatalogLayout>
      <ContentTitle backButton title="Настройки" />
      <div className="flex flex-col items-start">
        <div className="text-[#9b9b9b] text-xs font-bold tracking-wider mb-3">{formatPhone(user.mainPhone)}</div>

        <MySettingsForm user={user} />

        <UserActiveAddress type="settings" />
        <DeliveredNotifySwitch user={user} />
        <Link to="/agreement" className={linkCn}>
          Пользовательское соглашение и политика обработки персональных данных
        </Link>
        <ModalButton options={{ type: 'support' }} className={linkCn}>
          Связаться с поддержкой
        </ModalButton>
        <button
          type="button"
          onClick={() => {
            dispatch('setModal', {
              type: 'updatePassword',
              title: user.withPassword ? 'Сменить пароль' : 'Установить пароль',
            });
          }}
          className={linkCn}
        >
          {user.withPassword ? 'Сменить пароль' : 'Установить пароль'}
        </button>
        <button type="button" onClick={logOut} className={linkCn}>
          Выйти из аккаунта
        </button>
      </div>
    </CatalogLayout>
  );
};

export default Settings;

gql`
  query MySettingsPageQuery {
    viewer {
      id
      user {
        id
        mainPhone
        withPassword
        ...MySettingsForm_user
        ...DeliveredNotifySwitch_user
      }
    }
  }
`;
