import * as React from 'react';
import { MainLayout } from './MainLayout';
import { Props as CategorySidebarProps } from './CategorySidebar';
import { CatalogLayoutWithoutMain } from './CatalogLayoutWithoutMain';

type Props = {
  showMobileCategorySidebar?: boolean;
} & CategorySidebarProps;

export const CatalogLayout: React.FC<Props> = ({ children, showMobileCategorySidebar, ...sidebarProps }) => (
  <MainLayout raiseChatOnMobile={showMobileCategorySidebar}>
    <CatalogLayoutWithoutMain showMobileCategorySidebar={showMobileCategorySidebar} {...sidebarProps}>
      {children}
    </CatalogLayoutWithoutMain>
  </MainLayout>
);
